<!-- 健康管理建议 -->
<template>
    <div style="padding:20px 24px 40px 24px">
        <div class="globalTitle" style="color:#FF830F;margin-bottom: 20px;">健康的生活方式</div>
        <div class="globalText" style="margin-bottom: 20px;">
            <div class="top">饮食</div>
            <div class="content">
                <div style="margin-bottom: 14px;">均衡营养元素摄入。建议平均每天摄入蔬菜达到或接近500g，并且尽可能选择多种蔬 菜，同时注意补充优质蛋白，优先选择鸡肉、鱼肉、虾、蛋和牛奶。
                </div>
                <div style="margin-bottom: 14px;">注意补充水分。建议每天饮水1500~1700ml。</div>
                <div style="margin-bottom: 14px;">规律饮食。饮食不规律可能破坏生物钟对肠道免疫的调节功能，导致肠道免疫长期处于激活状态，可能发展为肠道慢性炎症。</div>
                <div style="margin-bottom: 14px;">减少高脂肪、高热量和加工类食品的摄入。如油炸食品、含糖饮料、熏肉等。</div>
                <div>戒烟、限酒。</div>
            </div>
        </div>
        <div class="globalText" style="margin-bottom: 20px;">
            <div class="top">睡眠</div>
            <div class="content">规律作息。尽量避免熬夜，建议每天的睡眠时长控制在6-8小时为佳。睡眠时长过少或过长均有可能增加心脑血管等疾病的风险。
            </div>
        </div>
        <div class="globalText" style="margin-bottom: 20px;">
            <div class="top">运动</div>
            <div class="content">
                有计划的运动。运动可以改善免疫力，世界卫生组织建议健身防病的运动量为每天≥30分钟中等强度运动，每周运动5次。运动类型可以选择有氧运动如游泳、慢跑、快走等，中老年人可选择太极拳等，也可以结合自身情况阶段性增加运动强度。
            </div>
        </div>
        <div class="globalText" style="margin-bottom: 20px;">
            <div class="top">情绪</div>
            <div class="content">长期压力状态会损伤人体免疫功能。积极乐观的心态是免疫系统的调节按钮，有助于整体健康，促进免疫系统功能的修复。
            </div>
        </div>

        <div class="globalTitle" style="color:#FF830F;margin:30px 0 20px 0">可遵医嘱补充营养素调理亚健康</div>
        <div class="globalText" style="margin-bottom: 20px;">
            <div class="top">益生菌</div>
            <div class="content">
                益生菌是定殖于人体肠道，能改善宿主微生态平衡的活性微生物。通过调节宿主黏膜与免疫功能或通过调节肠道内菌群平衡，促进营养吸收保持肠道健康。如双歧杆菌、乳酸菌。 </div>
        </div>
        <div class="globalText" style="margin-bottom: 20px;">
            <div class="top">益生元</div>
            <div class="content">
                益生元作为一种膳食用的补充剂，具有增殖有益菌、抑制有害菌、调节肠道菌群、增强免疫力、润肠通便、促进矿物质吸收的作用,对结肠癌、炎症性肠道疾病和急性感染具有有益的影响。益生元包括低聚糖类、微藻类、蛋白质水解物。
            </div>
        </div>
        <div class="globalText" style="margin-bottom: 20px;">
            <div class="top">合生元</div>
            <div class="content">
                益生菌和益生元的复合制剂。
            </div>
        </div>
        <div class="globalText" style="margin-bottom: 20px;">
            <div class="top">维生素</div>
            <div class="content">
                <div style="margin-bottom: 14px;">维生素是维持身体健康所必需的一类有机化合物。</div>
                <div style="margin-bottom: 14px;">维生素A具有维持正常视觉、促进上皮组织增殖分化和促进儿童生长发育等功能,是免疫力第一道防线的“守护神”。</div>
                <div style="margin-bottom: 14px;">维生素B是参与人体三大营养素新陈代谢的重要辅酶，能介导免疫调节，辅助免疫系统的正常运作。</div>
                <div>维生素C有促进胶原蛋白合成、促进伤口愈合、美白肌肤等功效，通过支持非特异性免疫和特异性免疫反应中各种细胞功能，有助于免疫防御。</div>
            </div>
        </div>
        <div class="globalText" style="margin-bottom: 20px;">
            <div class="top">蛋白质</div>
            <div class="content">蛋白质是形成抗体的重要物质基础，可适当补充优质蛋白质。
            </div>
        </div>
        <div class="globalText" style="margin-bottom: 20px;">
            <div class="top">辅酶Q10</div>
            <div class="content">
                辅酶Q10属于脂溶性抗氧化剂的一种，是一种常见的膳食补充剂。其将人体细胞能量进行激活后，人体的免疫力就会有所提高，同时也会增强人体的抗氧化性。
            </div>
        </div>

        <div class="globalTitle" style="color:#FF830F;margin:30px 0 16px 0">可遵循中医建议，适当进行理疗或中药调理 </div>
        <div class="globalText">
            谨遵医嘱，可适当进行理疗（推拿，拔罐、针灸和按摩等）或中药调理，通过调节机体自身的免疫系统，平衡免疫功能，增强自身对抗细菌和病毒感染的能力。中药可以全方位调节免疫功能，可遵医嘱辩证使用，以达到预防或治疗疾病改善自身免疫功能的目的。
        </div>
        <div class="globalTitle" style="color:#FF830F;margin:30px 0 16px 0">前沿细胞技术建议 </div>
        <div class="globalText">
            当前，细胞研究与应用治疗在生命科学领域备受重视。干细胞和免疫细胞技术在抗衰管理、改善亚健康、调节免疫功能，防癌抗癌等预防及治疗疾病方面的应用也越来越广泛。 </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
        }
    },
    created() {
    },
    mounted() {
        // 置顶
        window.scroll({ top: 0, left: 0 });
    },
    computed: {
    },
    methods: {
    },
}
</script>
<style lang="scss" scoped>
.top {
    width: 100%;
    height: 40px;
    background: #FFAA5B;
    border-radius: 8px 8px 0px 0px;
    padding: 9px 12px;
    box-sizing: border-box;
    font-size: 14px;

    color: #FFFFFF;
    line-height: 22px;
    letter-spacing: 1px;
}

.content {
    width: 100%;
    background: #FFFBF7;
    padding: 9px 12px;
    box-sizing: border-box;
}
</style>
